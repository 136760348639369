import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash';

// import crestPlaceholder from 'assets/images/crest-placeholder.png';

const ClubSummary = ({club, handleClubSelection, crestPlaceholder}) => {

  const handleClick = (e) => {
    e.preventDefault();
    handleClubSelection(club);
  }

  const logo = _.get(club, 'logo.small_thumb.url', crestPlaceholder);

  return(
    <a href="#" className="search-dropdown-item" onClick={handleClick}>
      <div className="search-dropown-icon">
        <img src={logo} alt={club.name} />
      </div>

      <div className="search-dropdown-copy">
        <p className="search-dropdown-club-name">{club.name}</p>
        <p className="search-dropdown-club-sport">{club.club_type_name}</p>
        <p className="search-dropdown-club-location">{club.county_name}, {club.country_name}</p>
      </div>
    </a>
  );
}

ClubSummary.propTypes = {
  club: PropTypes.object.isRequired,
  handleClubSelection: PropTypes.func.isRequired,
  crestPlaceholder: PropTypes.string.isRequired,
}

export default ClubSummary;