import React from 'react';
import PropTypes from 'prop-types';

import ClubSummary from './ClubSummary';

const TypeAheadDropdown = ({data, dropdownClassName, loading, handleClubSelection, crestPlaceholder}) => {

  if (loading) {
    return(
      <div className={dropdownClassName}>
        <p>Loading...</p>
      </div>
    );
  } else if (data.length > 0 && !loading) {
    return (
      <div className={dropdownClassName}>
        {data.map( (club, i) => {
          return (<ClubSummary key={i} club={club} handleClubSelection={handleClubSelection} crestPlaceholder={crestPlaceholder} />)
        })}
      </div>
    );
  } else {
    return(<span></span>);
  }
}

TypeAheadDropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dropdownClassName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  handleClubSelection: PropTypes.func.isRequired,
  crestPlaceholder: PropTypes.string.isRequired,
}

export default TypeAheadDropdown;