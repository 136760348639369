import React from 'react';
import PropTypes from 'prop-types';
import {filter} from 'lodash/filter';

import TypeAheadDropdown from './TypeAheadDropdown';

export default class TypeAhead extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      data: [],
      filteredData: [],
      loading: false,
      submitDisabled: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.searchClubs = this.searchClubs.bind(this);
    this.filterData = this.filterData.bind(this);
    this.handleClubSelection = this.handleClubSelection.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  // <input type="text" class="header-form-input" placeholder="Enter club name">

  render () {
    return (
      <div>
        <form action="/signup" onSubmit={this.submitForm}>
          <input type="text"
            name="club_name"
            className={this.props.inputClassName}
            placeholder="Enter club name"
            value={this.state.inputValue}
            onChange={this.handleChange} />
          <button type="submit" disabled={this.state.submitDisabled}>{this.props.buttonText}</button>
          <TypeAheadDropdown
            data={this.state.filteredData}
            dropdownClassName={this.props.dropdownClassName}
            loading={this.state.loading}
            handleClubSelection={this.handleClubSelection}
            crestPlaceholder={this.props.crestPlaceholder} />
        </form>
      </div>
    );
  }

  filterData(text) {
    let filteredData = filter(this.state.data, (item) => {
        return item.name.toLowerCase().indexOf(text.toLowerCase()) !== -1;
      }
    )
    this.setState({filteredData: filteredData})
  }

  handleChange(event) {
    this.setState({inputValue: event.target.value});
    this.searchClubs(event.target.value);
  }

  handleClubSelection(club) {
    this.setState({inputValue: club.name, data: [], filteredData: []});
  }

  searchClubs(text) {
    if (text.length == 4 && this.state.data.length == 0) {

      this.setState({loading: true});

      $.ajax({
        url: `/api/clubs`,
        dataType: 'json',
        headers: {
          "Accept":"application/json;version=3",
          "X-Club-App-Application-Id":"ClubZapWebsite"
        },
        type: 'GET',
        data: { search: text },
        success: (data) => {
          this.setState({data: data, loading: false, filteredData: data});
        },
        error: (xhr, status, err) => {
          console.error(status, err.toString());
        }
      });

    } else if (text.length > 4) {
      this.filterData(text);
    } else {
      this.setState({data: [], filteredData: []})
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({submitDisabled: true});
    console.log(e);
    e.target.submit();
  }

}

TypeAhead.propTypes = {
  buttonText: PropTypes.string.isRequired,
  inputClassName: PropTypes.string.isRequired,
  dropdownClassName: PropTypes.string.isRequired,
  crestPlaceholder: PropTypes.string.isRequired,
};
