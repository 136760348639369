import "trix"
import "@rails/actiontext"

import ReactOnRails from "react-on-rails";

import TypeAhead from "components/TypeAhead/TypeAhead";
import DobPicker from "components/DatePickers/DobPicker";
import MembershipCheckout from "Modules/MembershipCheckout";

ReactOnRails.register({ TypeAhead, DobPicker, MembershipCheckout });
